<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowAddForm"
          >
            New Account
          </b-button>
        </b-col> -->
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowEditForm(row.item)"
            >
              View
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-account-form"
      size="xl"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <b-tabs align="center">
        <b-tab
          title="Account Information"
        >
          <ValidationObserver ref="form">
            <form
              id="form"
              role="form"
              novalidate
              @submit.prevent
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="business name"
                    vid="business_name"
                    rules="required|max:200"
                  >
                    <b-form-group>
                      <label for="business_name">
                        <strong>Business Name</strong>
                      </label>
                      <b-input
                        id="business_name"
                        v-model="account.business_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter business name"
                        autocomplete="off"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="business type"
                    vid="business_type"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="business_type">
                        <strong>Business Type</strong>
                      </label>
                      <b-form-select
                        id="business_type"
                        v-model="selected.business_type"
                        :options="list.businessTypes"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy || fetching.businessTypes || account.requirements.length > 0"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- select business type --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="year started"
                    vid="year_started"
                    rules="required|integer"
                  >
                    <b-form-group>
                      <label for="year_started">
                        <strong>Year Started</strong>
                      </label>
                      <b-input
                        id="year_started"
                        v-model="account.year_started"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        minlength="4"
                        placeholder="enter year started"
                        autocomplete="off"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="number of stores"
                    vid="no_of_stores"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="no_of_stores">
                        <strong>Number of Stores</strong>
                      </label>
                      <b-input
                        id="no_of_stores"
                        v-model="account.no_of_stores"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter number of stores"
                        autocomplete="off"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-row class="py-2">
                    <b-col
                      cols="12"
                    >
                      <h5><strong>Business Address</strong></h5>
                    </b-col>
                    <b-col
                      cols="12"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="business address"
                        vid="business_address"
                        rules="required|max:200"
                      >
                        <b-form-group>
                          <label for="business_address">
                            <strong>No. / Subdivision / Street</strong>
                          </label>
                          <b-textarea
                            id="business_address"
                            v-model="account.business_address"
                            rows="6"
                            max-rows="8"
                            :state="errors.length > 0 ? false : null"
                            placeholder="enter business address"
                            autocomplete="off"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="province of business"
                        vid="province_business"
                        rules="required"
                      >
                        <b-form-group>
                          <label for="province_of_business">Province</label>
                          <v-select
                            id="province_of_business"
                            v-model="selected.province_of_business"
                            :options="list.provincesOfBusiness"
                            :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                            :disabled="state.busy || fetching.provincesOfBusiness"
                            :loading="fetching.provincesOfBusiness"
                            placeholder=" -- select province -- "
                            label="province_name"
                          >
                            <template #option="{ province_name }">
                              <strong>{{ province_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Provinces
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="municipality of business"
                        vid="municipality_business"
                        rules="required"
                      >
                        <b-form-group>
                          <label for="municipality_of_business">Municipality</label>
                          <v-select
                            id="municipality_of_business"
                            v-model="selected.municipality_of_business"
                            :options="list.municipalitiesOfBusiness"
                            :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                            :disabled="state.busy || fetching.municipalitiesOfBusiness"
                            :loading="fetching.municipalitiesOfBusiness"
                            placeholder=" -- select municipality -- "
                            label="municipality_name"
                          >
                            <template #option="{ municipality_name }">
                              <strong>{{ municipality_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Municipalities
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="barangay of business"
                        vid="barangay_business"
                        rules="required"
                      >
                        <b-form-group>
                          <label for="barangay_of_business">Barangay</label>
                          <v-select
                            id="barangay_of_business"
                            v-model="selected.barangay_of_business"
                            :options="list.barangaysOfBusiness"
                            :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                            :disabled="state.busy || fetching.barangaysOfBusiness"
                            :loading="fetching.barangaysOfBusiness"
                            placeholder=" -- select barangay -- "
                            label="barangay_name"
                          >
                            <template #option="{ barangay_name }">
                              <strong>{{ barangay_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Barangays
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-row class="py-2">
                    <b-col
                      cols="12"
                    >
                      <h5><strong>Home Address</strong></h5>
                    </b-col>
                    <b-col
                      cols="12"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="home address"
                        vid="home_address"
                        rules="required|max:200"
                      >
                        <b-form-group>
                          <label for="home_address">
                            <strong>No. / Subdivision / Street</strong>
                          </label>
                          <b-textarea
                            id="home_address"
                            v-model="account.home_address"
                            rows="6"
                            max-rows="8"
                            :state="errors.length > 0 ? false : null"
                            placeholder="enter home address"
                            autocomplete="off"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="province of home"
                        vid="province_home"
                        rules="required"
                      >
                        <b-form-group>
                          <label for="province_of_home">Province</label>
                          <v-select
                            id="province_of_home"
                            v-model="selected.province_of_home"
                            :options="list.provincesOfHome"
                            :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                            :disabled="state.busy || fetching.provincesOfHome"
                            :loading="fetching.provincesOfHome"
                            placeholder=" -- select province -- "
                            label="province_name"
                          >
                            <template #option="{ province_name }">
                              <strong>{{ province_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Provinces
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="municipality of home"
                        vid="municipality_home"
                        rules="required"
                      >
                        <b-form-group>
                          <label for="municipality_of_home">Municipality</label>
                          <v-select
                            id="municipality_of_home"
                            v-model="selected.municipality_of_home"
                            :options="list.municipalitiesOfHome"
                            :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                            :disabled="state.busy || fetching.municipalitiesOfHome"
                            :loading="fetching.municipalitiesOfHome"
                            placeholder=" -- select municipality -- "
                            label="municipality_name"
                          >
                            <template #option="{ municipality_name }">
                              <strong>{{ municipality_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Municipalities
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="barangay of home"
                        vid="barangay_home"
                        rules="required"
                      >
                        <b-form-group>
                          <label for="barangay_of_home">Barangay</label>
                          <v-select
                            id="barangay_of_home"
                            v-model="selected.barangay_of_home"
                            :options="list.barangaysOfHome"
                            :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                            :disabled="state.busy || fetching.barangaysOfHome"
                            :loading="fetching.barangaysOfHome"
                            placeholder=" -- select barangay -- "
                            label="barangay_name"
                          >
                            <template #option="{ barangay_name }">
                              <strong>{{ barangay_name }}</strong>
                            </template>
                            <template #no-options="">
                              No Available Barangays
                            </template>
                          </v-select>
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="remarks"
                    vid="remarks"
                    rules="required|max:500"
                  >
                    <b-form-group>
                      <label for="remarks">
                        <strong>Remarks</strong>
                      </label>
                      <b-textarea
                        id="remarks"
                        v-model="account.remarks"
                        rows="6"
                        max-rows="8"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter remarks"
                        autocomplete="off"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </form>
          </ValidationObserver>
        </b-tab>
        <b-tab
          title="Contact Persons"
        >
          <b-row>
            <b-col
              cols="12"
            >

              <ValidationObserver ref="form_dealer_representative">
                <form
                  id="form_dealer_representative"
                  novalidate
                  @submit.prevent
                >
                  <b-row>

                    <b-col
                      cols="12"
                      md="12"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="contact name"
                        vid="name"
                        rules="required|max:200"
                      >
                        <b-form-group>
                          <label for="contact_name">
                            <strong>Name</strong>
                          </label>
                          <b-input
                            id="contact_name"
                            v-model="contactPerson.name"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            placeholder="enter contact name"
                            autocomplete="off"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="birth date"
                        vid="birth_date"
                        rules="required"
                      >
                        <b-form-group>
                          <label for="birth_date">
                            <strong>Birth Date</strong>
                          </label>
                          <b-input
                            id="birth_date"
                            v-model="contactPerson.birth_date"
                            type="date"
                            :state="errors.length > 0 ? false : null"
                            placeholder="enter birth date"
                            autocomplete="off"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="designation"
                        vid="designation"
                        rules="required|max:200"
                      >
                        <b-form-group>
                          <label for="designation">
                            <strong>Designation</strong>
                          </label>
                          <b-input
                            id="designation"
                            v-model="contactPerson.designation"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            placeholder="enter designation"
                            autocomplete="off"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="mobile name"
                        vid="mobile_number"
                        rules="required|min:11|max:11"
                      >
                        <b-form-group>
                          <label for="mobile_number">
                            <strong>Mobile Number</strong>
                          </label>
                          <b-input
                            id="mobile_number"
                            v-model="contactPerson.mobile_number"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            placeholder="enter mobile number"
                            maxlength="11"
                            autocomplete="off"
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mb-3"
                    >
                      <b-button
                        variant="success"
                        class="mr-2"
                        @click="onSaveDealerRepresentative"
                      >
                        Save Contact
                      </b-button>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="contact persons"
                        vid="contact_persons"
                      >
                        <b-form-group>
                          <b-input
                            id="contact_persons"
                            v-model="account.contact_persons.length"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                            hidden
                          />
                          <div
                            v-if="errors.length > 0"
                            class="invalid-feedback"
                          >
                            <span>{{ errors[0] }}</span>
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col cols="12">
                      <b-table
                        ref="items_table"
                        class="mt-1"
                        :fields="contactPersonTableSettings.fields"
                        :items="account.contact_persons"
                        responsive
                        striped
                        hover
                      >
                        <template #cell(index)="data">
                          {{ contactPersonTableSettings.currentPage * contactPersonTableSettings.perPage - contactPersonTableSettings.perPage + (data.index + 1) }}
                        </template>

                        <template #cell(name)="row">
                          <div class="text-nowrap">
                            {{ row.item.name }}
                          </div>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </form>
              </ValidationObserver>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab
          title="Requirements"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <ValidationObserver ref="form_requirements">
                <form
                  id="form_requirements"
                  novalidate
                  @submit.prevent
                >
                  <b-row>
                    <b-col
                      v-if="!state.editing"
                      cols="12"
                    >
                      <b-row>
                        <b-col
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            name="requirement type"
                            vid="requirement_type"
                            rules="required"
                          >
                            <b-form-group>
                              <label for="requirement_type">
                                <strong>Requirement</strong>
                              </label>
                              <v-select
                                id="requirement_type"
                                v-model="selected.requirement_type"
                                label="text"
                                :options="businessRequirements"
                                :disabled="state.busy || fetching.businessRequirements"
                                :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                                :loading="fetching.businessRequirements"
                                placeholder="-- select requirement type --"
                              >
                                <template #option="{ text }">
                                  <span>{{ text }}</span>
                                </template>
                                <template #no-options="">
                                  No Available Requirement Types
                                </template>
                              </v-select>
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            name="attachments"
                            vid="attachments"
                            rules="required"
                          >
                            <b-form-group>
                              <label for="attachments">
                                <strong>Attachment</strong>
                              </label>
                              <b-form-file
                                id="attachments"
                                v-model="requirements.file"
                                accept=".jpg, .jpeg, .png"
                                :class="errors.length > 0 ? `is-invalid` : ''"
                                :disabled="state.busy"
                              />
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col
                          cols="12"
                          class="mb-3"
                        >
                          <b-button
                            v-if="requirements.file"
                            size="sm"
                            variant="success"
                            class="mr-1"
                            :disabled="state.busy"
                            @click="onUploadRequirement"
                          >
                            Upload Files
                          </b-button>
                          <b-button
                            v-if="account.requirements.length > 0"
                            size="sm"
                            variant="danger"
                            :disabled="state.busy"
                            @click="onRemoveAllFile"
                          >
                            Remove Files
                          </b-button>
                        </b-col>
                        <b-col
                          cols="12"
                        >
                          <ValidationProvider
                            #default="{ errors }"
                            name="requirements"
                            vid="requirements"
                          >
                            <b-form-group>
                              <b-input
                                id="requirements"
                                v-model="account.requirements.length"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                hidden
                              />
                              <div
                                v-if="errors.length > 0"
                                class="invalid-feedback"
                              >
                                <span>{{ errors[0] }}</span>
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col
                      cols="12"
                    >
                      <b-table
                        ref="requirements_table"
                        :fields="requirementTableSettings.fields"
                        :items="account.requirements"
                        responsive
                        striped
                        hover
                      >
                        <template #cell(actions)="row">
                          <div class="text-nowrap">
                            <b-button
                              size="sm"
                              class="mr-1"
                              :disabled="state.busy"
                              @click="onPreviewAttachment(row.item)"
                            >
                              Preview
                            </b-button>
                            <b-button
                              v-if="!state.editing"
                              variant="danger"
                              size="sm"
                              :disabled="state.busy"
                              @click="onRemoveFile(row.item)"
                            >
                              Remove
                            </b-button>
                          </div>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </form>
              </ValidationObserver>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>

      <template #modal-footer="{ ok, cancel }">
        <div class="col-12 d-flex justify-content-between">
          <div>
            <b-button
              variant="success"
              class="mr-2"
              disabled
              @click="ok()"
            >
              Approve
            </b-button>
            <b-button
              variant="danger"
              disabled
              @click="ok()"
            >
              Disapprove
            </b-button>
          </div>
          <div>
            <b-button
              variant="danger"
              :disabled="state.busy"
              @click="cancel()"
            >
              Close Window
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="modal-preview-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attached Requirement [${file.name}]`"
      size="lg"
      centered
    >
      <div class="d-flex justify-content-center">
        <b-img
          fluid
          :src="file.url"
        />
      </div>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Preview
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, CollectionNewAccountService, DSMRequirementService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'

export default {
  name: 'CollectionNewAccounts',

  middleware: ['auth', 'collection'],

  metaInfo () {
    return {
      title: 'New Accounts'
    }
  },

  mixins: [formatter, url],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      file: {
        url: null,
        name: null
      },
      fetching: {
        provincesOfBusiness: false,
        municipalitiesOfBusiness: false,
        barangaysOfBusiness: false,
        provincesOfHome: false,
        municipalitiesOfHome: false,
        barangaysOfHome: false,
        businessRequirements: false
      },
      list: {
        businessTypes: [
          { value: 'Big Land Owner', text: 'Big Land Owner' },
          { value: 'Cooperative', text: 'Cooperative' },
          { value: 'Corporation', text: 'Corporation' },
          { value: 'Financer', text: 'Financer' },
          { value: 'Partnership', text: 'Partnership' },
          { value: 'Sole Proprietorship', text: 'Sole Proprietorship' }
        ],
        distributions: [],
        provincesOfBusiness: [],
        municipalitiesOfBusiness: [],
        barangaysOfBusiness: [],
        provincesOfHome: [],
        municipalitiesOfHome: [],
        barangaysOfHome: [],
        businessRequirements: []
      },
      selected: {
        current: null,
        business_type: null,
        requirement_type: null,
        province_of_business: null,
        municipality_of_business: null,
        barangay_of_business: null,
        province_of_home: null,
        municipality_of_home: null,
        barangay_of_home: null,
        contactPerson: null
      },
      requirements: {
        file: null,
        path: null,
        requirement_type: null
      },
      account: {
        id: 0,
        business_name: null,
        business_type: null,
        business_address: null,
        home_address: null,
        no_of_stores: null,
        year_started: null,
        distribution: null,
        province_business: null,
        municipality_business: null,
        barangay_business: null,
        province_home: null,
        municipality_home: null,
        barangay_home: null,
        remarks: null,
        contact_persons: [],
        requirements_path: null,
        requirements: []
      },
      contactPerson: {
        name: null,
        birth_date: null,
        designation: null,
        mobile_number: null
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'business_name' },
          { key: 'business_address' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      },
      contactPersonTableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          { key: 'name' },
          { key: 'birth_date', formatter: this.dateFormatter },
          { key: 'designation' },
          { key: 'mobile_number' }
        ]
      },
      requirementTableSettings: {
        fields: [
          'actions',
          { key: 'requirement_name' },
          { key: 'file_original_name', label: 'filename' }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Account' : 'New Account'
    },

    businessRequirements () {
      return this.list.businessRequirements.filter(br => !this.account.requirements.map(r => r.requirement_name).includes(br.text))
    }
  },

  watch: {

    'selected.business_type' (businessType) {
      this.account.business_type = businessType || null
      this.getBusinessRequirements(this.account.business_type)
    },

    'selected.requirement_type' (requirementType) {
      this.requirements.requirement_type = requirementType?.value || null
    },

    'selected.province_of_business' (provinceBusiness) {
      this.account.province_business = provinceBusiness?.id || null
      this.getMunicipalitiesOfBusiness(this.account.province_business)
    },

    'selected.municipality_of_business' (municipalityBusiness) {
      this.account.municipality_business = municipalityBusiness?.id || null
      this.getBarangaysOfBusiness(this.account.municipality_business)
    },

    'selected.barangay_of_business' (barangayBusiness) {
      this.account.barangay_business = barangayBusiness?.id || null
    },

    'selected.province_of_home' (provinceHome) {
      this.account.province_home = provinceHome?.id || null
      this.getMunicipalitiesOfHome(this.account.province_home)
    },

    'selected.municipality_of_home' (municipalityHome) {
      this.account.municipality_home = municipalityHome?.id || null
      this.getBarangaysOfHome(this.account.municipality_home)
    },

    'selected.barangay_of_home' (barangayHome) {
      this.account.barangay_home = barangayHome?.id || null
    }
  },

  async mounted () {
    core.index()
    await this.getProvincesOfBusiness()
    await this.getProvincesOfHome()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await CollectionNewAccountService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getDSMDistributions () {
      this.fetching.distributions = true
      await SharedListService.getClusters().then(({ data }) => {
        this.list.distributions = data
        this.fetching.distributions = false
      })
    },

    async getBusinessRequirements (businessType) {
      this.selected.requirement_type = null
      if (businessType) {
        this.fetching.businessRequirements = true
        await SharedListService.getBusinessRequirements(`business_type=${businessType}`).then(({ data }) => {
          this.list.businessRequirements = data.map(({ id, requirement_name }) => ({
            text: requirement_name,
            value: id
          }))
          this.fetching.businessRequirements = false
        })
      } else {
        this.list.businessRequirements = []
      }
    },

    async getProvincesOfBusiness () {
      this.fetching.provincesOfBusiness = true
      await SharedListService.getProvinces().then(({ data }) => {
        this.list.provincesOfBusiness = data
        this.fetching.provincesOfBusiness = false
        if (this.state.editing) {
          if (this.selected.current.province_of_business) {
            this.selected.province_of_business = {
              id: this.selected.current.province_of_business.id,
              province_name: this.selected.current.province_of_business.province_name
            }
            this.selected.current.province_of_business = null
          }
        }
      })
    },

    async getMunicipalitiesOfBusiness (province) {
      this.selected.municipality_of_business = null
      if (province) {
        this.fetching.municipalitiesOfBusiness = true
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.municipalitiesOfBusiness = data
          this.fetching.municipalitiesOfBusiness = false
          if (this.state.editing) {
            if (this.selected.current.municipality_of_business) {
              this.selected.municipality_of_business = {
                id: this.selected.current.municipality_of_business.id,
                municipality_name: this.selected.current.municipality_of_business.municipality_name
              }
              this.selected.current.municipality_of_business = null
            }
          }
        })
      } else {
        this.list.municipalitiesOfBusiness = []
      }
    },

    async getBarangaysOfBusiness (municipality) {
      this.selected.barangay_of_business = null
      if (municipality) {
        this.fetching.barangaysOfBusiness = true
        await SharedListService.getBarangays(`municipality_id=${municipality}`).then(({ data }) => {
          this.list.barangaysOfBusiness = data
          this.fetching.barangaysOfBusiness = false
          if (this.state.editing) {
            if (this.selected.current.barangay_of_business) {
              this.selected.barangay_of_business = {
                id: this.selected.current.barangay_of_business.id,
                barangay_name: this.selected.current.barangay_of_business.barangay_name
              }
              this.selected.current.barangay_of_business = null
            }
          }
        })
      } else {
        this.list.barangaysOfBusiness = []
      }
    },

    async getProvincesOfHome () {
      this.fetching.provincesOfHome = true
      await SharedListService.getProvinces().then(({ data }) => {
        this.list.provincesOfHome = data
        this.fetching.provincesOfHome = false
        if (this.state.editing) {
          if (this.selected.current.province_of_home) {
            this.selected.province_of_home = {
              id: this.selected.current.province_of_home.id,
              province_name: this.selected.current.province_of_home.province_name
            }
            this.selected.current.province_of_home = null
          }
        }
      })
    },

    async getMunicipalitiesOfHome (province) {
      this.selected.municipality_of_home = null
      if (province) {
        this.fetching.municipalitiesOfHome = true
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.municipalitiesOfHome = data
          this.fetching.municipalitiesOfHome = false
          if (this.state.editing) {
            if (this.selected.current.municipality_of_home) {
              this.selected.municipality_of_home = {
                id: this.selected.current.municipality_of_home.id,
                municipality_name: this.selected.current.municipality_of_home.municipality_name
              }
              this.selected.current.municipality_of_home = null
            }
          }
        })
      } else {
        this.list.municipalitiesOfHome = []
      }
    },

    async getBarangaysOfHome (municipality) {
      this.selected.barangay_of_home = null
      if (municipality) {
        this.fetching.barangaysOfHome = true
        await SharedListService.getBarangays(`municipality_id=${municipality}`).then(({ data }) => {
          this.list.barangaysOfHome = data
          this.fetching.barangaysOfHome = false
          if (this.state.editing) {
            if (this.selected.current.barangay_of_home) {
              this.selected.barangay_of_home = {
                id: this.selected.current.barangay_of_home.id,
                barangay_name: this.selected.current.barangay_of_home.barangay_name
              }
              this.selected.current.barangay_of_home = null
            }
          }
        })
      } else {
        this.list.barangaysOfHome = []
      }
    },

    onShowAddForm () {
      this.state.editing = false

      this.account.id = 0
      this.account.business_name = null
      this.account.business_type = null

      this.account.business_address = null
      this.account.province_business = null
      this.account.municipality_business = null
      this.account.barangay_business = null

      this.account.home_address = null
      this.account.province_home = null
      this.account.municipality_home = null
      this.account.barangay_home = null

      this.account.year_started = null
      this.account.no_of_stores = null

      this.account.remarks = null
      this.account.contact_persons = []

      this.account.requirements = []
      this.account.requirements_path = null

      this.selected.business_type = null
      this.selected.requirement_type = null

      this.selected.province_of_business = null
      this.selected.municipality_of_business = null
      this.selected.barangay_of_business = null

      this.selected.province_of_home = null
      this.selected.municipality_of_home = null
      this.selected.barangay_of_home = null

      this.selected.contactPerson = null

      this.$bvModal.show('modal-account-form')
    },

    onShowEditForm (account) {
      this.state.editing = true

      this.account.id = account.id
      this.account.business_name = account.business_name
      this.account.business_address = account.business_address
      this.account.home_address = account.home_address
      this.account.year_started = account.year_started
      this.account.remarks = account.remarks
      this.account.contact_persons = account.contact_persons
      this.account.requirements = account.requirements
      this.account.requirements_path = account.requirements_path
      this.account.no_of_stores = account.no_of_stores

      this.selected.current = { ...account }
      this.selected.contactPerson = null

      this.selected.business_type = account.business_type

      if (account.province_of_business) {
        this.selected.province_of_business = {
          id: account.province_of_business.id,
          province_name: account.province_of_business.province_name
        }
      }

      if (account.municipality_of_business) {
        this.selected.municipality_of_business = {
          id: account.municipality_of_business.id,
          municipality_name: account.municipality_of_business.municipality_name
        }
      }

      if (account.barangay_of_business) {
        this.selected.barangay_of_business = {
          id: account.barangay_of_business.id,
          barangay_name: account.barangay_of_business.barangay_name
        }
      }

      if (account.province_of_home) {
        this.selected.province_of_home = {
          id: account.province_of_home.id,
          province_name: account.province_of_home.province_name
        }
      }

      if (account.municipality_of_home) {
        this.selected.municipality_of_home = {
          id: account.municipality_of_home.id,
          municipality_name: account.municipality_of_home.municipality_name
        }
      }

      if (account.barangay_of_home) {
        this.selected.barangay_of_home = {
          id: account.barangay_of_home.id,
          barangay_name: account.barangay_of_home.barangay_name
        }
      }

      this.$bvModal.show('modal-account-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success && this.account.contact_persons.length > 1 && this.account.requirements.length === this.list.businessRequirements.length) {
          let title = 'Do you really want to create account?'

          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              } else {
                return this.onFormPostSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          if (this.account.contact_persons.length <= 1) {
            this.$refs.form_dealer_representative.setErrors({
              contact_persons: [
                'The contact persons must be atleast 2 members'
              ]
            })
          }

          if (this.account.requirements.length <= 0) {
            this.$refs.form_requirements.setErrors({
              requirements: [
                this.list.businessRequirements.length > 0 ? `The requirements are required, it must be ${this.list.businessRequirements.length} attachments in total.` : 'The requirements are required'
              ]
            })
          }

          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        CollectionNewAccountService.post(this.account).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-account-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              this.$refs.form_dealer_representative.setErrors(error.message)
              this.$refs.form_requirements.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        CollectionNewAccountService.put(this.account).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-account-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: data.account.id })
            row.business_name = data.account.business_name
            row.business_address = data.account.business_address
            row.home_address = data.account.home_address
            row.contact_persons = data.account.contact_persons
            row.requirements = data.account.requirements

            row.year_started = data.account.year_started
            row.no_of_stores = data.account.no_of_stores

            row.province_of_business = data.account.province_of_business
            row.province_one_id = data.account.province_one_id
            row.municipality_of_business = data.account.municipality_of_business
            row.municipality_one_id = data.account.municipality_one_id
            row.barangay_of_business = data.account.barangay_of_business
            row.barangay_one_id = data.account.barangay_one_id

            row.province_of_home = data.account.province_of_home
            row.province_two_id = data.account.province_two_id
            row.municipality_of_home = data.account.municipality_of_home
            row.municipality_two_id = data.account.municipality_two_id
            row.barangay_of_home = data.account.barangay_of_home
            row.barangay_two_id = data.account.barangay_two_id

            row.remarks = data.account.remarks
            row.updated_at = data.account.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              resolve(error)
            }
          }
        })
      })
    },

    onClearContactPerson () {
      this.selected.contactPerson = null
      this.contactPerson = {
        name: null,
        birth_date: null,
        designation: null,
        mobile_number: null
      }

      this.$refs.form_dealer_representative.reset()
    },

    onEditContactPerson (contactPerson, index) {
      this.selected.contactPerson = index
      this.contactPerson = contactPerson
    },

    onRemoveContactPerson (contactPerson) {
      this.account.contact_persons = this.account.contact_persons.filter(find => find !== contactPerson)
    },

    async onSaveDealerRepresentative (btnEvt) {
      btnEvt.preventDefault()

      await this.$refs.form_dealer_representative.validate().then(
        async success => {
          const mobiles = this.account.contact_persons.filter((validate, index) => validate.mobile_number === this.contactPerson.mobile_number && index !== this.selected.contactPerson)

          if (success && mobiles.length <= 0) {
            if (this.selected.contactPerson !== null) {
              this.account.contact_persons[this.selected.contactPerson] = this.contactPerson
            } else {
              this.account.contact_persons.push(this.contactPerson)
            }

            this.onClearContactPerson()
          } else {
            if (mobiles.length >= 1) {
              this.$refs.form_dealer_representative.setErrors({
                mobile_number: ['The mobile number already exists.']
              })
            }

            this.$bvModal.msgBoxOk(
              'Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              }
            )
          }
        }
      )
    },

    async onUploadRequirement () {
      await this.$refs.form_requirements.validate().then(
        async success => {
          if (success) {
            this.state.busy = true

            const formBody = new FormData()
            formBody.append('file_path', this.requirements.path)
            formBody.append('file', this.requirements.file)
            formBody.append('requirement_type', this.requirements.requirement_type)

            await DSMRequirementService.upload(formBody).then(({ data }) => {
              this.account.requirements_path = this.requirements.path = data.file_path

              this.account.requirements.push({
                requirement_name: data.requirement.requirement_name,
                file_original_name: data.file.file_original_name,
                file_hash_name: data.file.file_hash_name,
                file_extension: data.file.file_extension
              })

              this.selected.requirement_type = null
              this.requirements.file = null
              this.state.busy = false
            }).catch(error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.form_requirements.setErrors({
                    requirements: [error.message]
                  })
                }
              }

              this.requirements.file = null
              this.state.busy = false
            })

            this.$refs.form_requirements.reset()
          } else {
            this.$bvModal.msgBoxOk(
              'Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              }
            )
          }
        }
      )
    },

    async onRemoveFile (file) {
      this.state.busy = true
      await DSMRequirementService.removeFile(this.account.requirements_path, file.file_hash_name).then(() => {
        this.account.requirements = this.account.requirements.filter(requirement => requirement.file_hash_name !== file.file_hash_name)
        this.state.busy = false
      })
    },

    async onRemoveAllFile () {
      this.state.busy = true
      DSMRequirementService.removeAllFile(this.account.requirements_path).then(() => {
        this.account.requirements = []
        this.state.busy = false
      })
    },

    async onPreviewAttachment (file) {
      if (this.state.editing) {
        this.file.name = file.file_original_name
        this.file.url = this.getImageUrl(`${this.account.requirements_path}/${file.file_hash_name}`)
        this.$bvModal.show('modal-preview-image')
      } else {
        this.file.name = file.file_original_name
        this.file.url = this.getImageUrl(`uploads/${this.account.requirements_path}/${file.file_hash_name}`)
        this.$bvModal.show('modal-preview-image')
      }
    }
  }
}
</script>
